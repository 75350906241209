import MainCard from "@/components/atoms/Card/MainCard";
import ChatbotCustomTemplate from "@/components/organisms/ChatbotCustomTemplate";
// import ChatbotCustomTemplate from "@/components/organisms/ChatbotCustomTemplate";
import {
  MessageLeft,
  MessageRight,
} from "@/components/organisms/ChatbotMessages";
import ChatbotSidebar from "@/components/organisms/ChatbotSidebar";
import { ChatbotTextInput } from "@/components/organisms/ChatbotTextInput";
import useChatbot from "@/hooks/chatbot/useChatbot";
import useChatbotConversation from "@/hooks/chatbot/useChatbotConversation";
import useChatbotMessageFeedback from "@/hooks/chatbot/useChatbotMessageFeedback";
import useChatbotMessageReaction from "@/hooks/chatbot/useChatbotMessageReaction";
import useChatbotSessions from "@/hooks/chatbot/useChatbotSessions";
import useFetchAccountState from "@/hooks/store-management/useFetchAccountState";
import { IConversationEvents } from "@/models/conversation";
import { IThreadMessage } from "@/models/threadMessage";
import { useAppDispatch, useAppSelector } from "@/store";
import { setSessionId } from "@/store/slices/chatbot";
import { sendEventToParentContainer } from "@/utils/events.util";
import { Box, CircularProgress, Grid, Paper, Typography } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router";

interface IChatBotTemplate {
  sessionId?: string;
  data_model?: string;
  sku?: string;
  events?: IConversationEvents;
  info_agent_webview?: boolean;
  instant_chat?: boolean;
}

export function ChatBotTemplate({
  sessionId,
  data_model,
  sku,
  events,
  info_agent_webview = false,
  instant_chat,
}: IChatBotTemplate) {
  console.log("instant chat is .. ", instant_chat);
  const dispatch = useAppDispatch();
  const { sessionId: chatbotSessionId } = useAppSelector(
    (state) => state.chatbot
  );

  const effectiveSessionId = info_agent_webview ? chatbotSessionId : sessionId;
  const [thread, setThread] = useState<IThreadMessage[]>([]);

  const shouldInitConversation = () => {
    if (!info_agent_webview) return false;
    if (instant_chat && thread.length === 1) return true;
    if (!instant_chat && thread.length === 0) return true;
    return false;
  };

  const { queryChatbot, isLoading } = useChatbot({
    session_id: effectiveSessionId,
    info_agent_webview,
    data_model: info_agent_webview ? data_model : null,
    sku: info_agent_webview ? sku : null,
    events,
    init_conversation: shouldInitConversation(),
  });

  const messageEndRef = useRef<HTMLDivElement>(null);

  const { accountState } = useFetchAccountState({
    fetchOnlyStore: true,
  });

  const { queryChatbotMessageReaction } = useChatbotMessageReaction({});

  const { data: chatbotSessionsData, refetch: refetchChatbotSessions } =
    useChatbotSessions({
      store: info_agent_webview && data_model ? "" : accountState.name,
    });

  const { fetchConversation, isLoading: conversationLoading } =
    useChatbotConversation();

  const navigate = useNavigate();
  const location = useLocation();

  const { queryChatbotFeedback, isLoading: feedbackLoading } =
    useChatbotMessageFeedback({});

  useEffect(() => {
    if (info_agent_webview) {
      const paperElement = document.getElementById("main-chatbot-paper");
      if (paperElement) {
        paperElement.scrollTop = paperElement.scrollHeight;
      }
      if (thread.length === 0 && instant_chat) {
        onPromptSubmit("", true);
      }
    } else {
      messageEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info_agent_webview, thread, instant_chat]);

  const handleFetchConversation = useCallback(
    async ({ session_id }: { session_id: string }) => {
      const conversation = await fetchConversation({ session_id });
      if (conversation) {
        setThread(conversation);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (sessionId) {
      console.log("Inside session id location state block");
      if (location.state?.thread) {
        setThread(location.state?.thread);
        refetchChatbotSessions();
      } else {
        // Fetchin conversation data..
        handleFetchConversation({ session_id: sessionId });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleFetchConversation, location.state, sessionId]);

  const onPromptSubmit = async (prompt: string, isIntro: boolean = false) => {
    console.log("prompt is ", prompt);
    if (!isIntro) {
      setThread((thread) => [
        ...thread,
        { orientation: "right", message: prompt, request_id: "", sql: null },
      ]);
    }

    const result = await queryChatbot({
      prompt,
      isIntro,
    });
    if (result) {
      if (
        result?.session_id &&
        result?.session_id != sessionId &&
        !info_agent_webview
      ) {
        console.log("current thread is ", thread);
        navigate(
          `/databot/${
            result?.session_id
          }?account=${accountState.name.toLowerCase()}`,
          {
            state: {
              thread: [
                {
                  orientation: "right",
                  message: prompt,
                  request_id: "",
                  sql: null,
                },
                {
                  orientation: "left",
                  message: result?.data?.response,
                  request_id: result?.request_id,
                  sql: result?.data?.sql,
                  image: result?.data?.image,
                  chart: result?.data?.chart,
                  response_total_time: result?.data?.response_time?.total_time,
                },
              ],
            },
          }
        );
      } else {
        if (info_agent_webview && result?.session_id) {
          dispatch(setSessionId(result.session_id));
          if(shouldInitConversation()) {
            // Sending event to parent..
            sendEventToParentContainer({conversation_id: result?.session_id})
          } else {
            console.log('Initializing conversation : false')
          }
        }

        setThread((thread) => [
          ...thread,
          {
            orientation: "left",
            message: result?.data?.response,
            request_id: result?.request_id,
            sql: result?.data?.sql,
            image: result?.data?.image,
            chart: result?.data?.chart,
            response_total_time: result?.data?.response_time?.total_time,
          },
        ]);
      }
    }
  };

  const handleThumbsUp = (request_id?: string) => {
    console.log("thread is ", thread, request_id);
    if (!request_id) return;

    // Check if thread already has any reaction..
    const threadReaction = thread.find(
      (e) => e.request_id === request_id
    )?.like;
    if (threadReaction === true) return;

    // Remote and local update to the reaction..
    queryChatbotMessageReaction({ like: true, request_id });
    setThread((thread) =>
      thread.map((e) =>
        e.request_id === request_id ? { ...e, like: true } : e
      )
    );
  };
  const handleThumbsDown = (request_id?: string) => {
    if (!request_id) return;

    // Check if thread already has any reaction..
    const threadReaction = thread.find(
      (e) => e.request_id === request_id
    )?.like;
    if (threadReaction === false) return;

    // Remote and local update to the reaction..
    queryChatbotMessageReaction({ like: false, request_id });
    setThread((thread) =>
      thread.map((e) =>
        e.request_id === request_id ? { ...e, like: false } : e
      )
    );
  };

  const handleQueryChatbotFeedback = async ({
    request_id,
    feedback,
  }: {
    request_id?: string;
    feedback: string;
  }) => {
    if (!request_id) return;
    await queryChatbotFeedback({ comment: feedback, request_id });
    setThread((thread) =>
      thread.map((e) =>
        e.request_id === request_id ? { ...e, feedback: feedback } : e
      )
    );
  };

  const onCustomPromptSubmit = (prompt: string) => {
    onPromptSubmit(prompt);
  };

  console.log("thread is ", thread);
  return (
    <div
      style={{
        height: "100%",
      }}
    >
      <MainCard
        sx={
          {
            // height: "100%",
          }
        }
        contentSX={
          {
            // height: "calc(100vh - 60px)",
            // display: "flex",
            // flexDirection: "row",
          }
        }
      >
        <Grid container>
          <Grid item xs={!info_agent_webview ? 9.5 : 12}>
            <Box
              sx={{
                height: info_agent_webview
                  ? "calc(100vh - 35px)"
                  : "calc(100vh - 90px)",
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
              }}
            >
              <Paper
                id="main-chatbot-paper"
                sx={{
                  flexGrow: 1,
                  overflow: "auto",
                }}
              >
                <Typography color={"primary"} fontWeight={"bold"}>
                  {/* <span className="orange-text">AdScore AI</span> */}
                  AI-Agent
                  {/* <span className="orange-text">AdScore AI</span> */}
                </Typography>
                <br />

                {!thread[0] && !conversationLoading && (
                  <ChatbotCustomTemplate
                    onCustomPromptSubmit={onCustomPromptSubmit}
                  />
                )}

                {conversationLoading && (
                  <Box
                    sx={{
                      width: "100%",
                      height: "90%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      gap: 4,
                    }}
                  >
                    <CircularProgress disableShrink />
                    <Typography>Loading Conversation ...</Typography>
                  </Box>
                )}
                {thread.map((threadItem) =>
                  threadItem.orientation === "right" ? (
                    <MessageRight
                      message={threadItem.message}
                      key={threadItem.message}
                    />
                  ) : (
                    <MessageLeft
                      feedback={threadItem.feedback}
                      message={threadItem.message}
                      key={threadItem.message}
                      sql={threadItem.sql}
                      like={threadItem.like}
                      request_id={threadItem.request_id}
                      onThumbsUp={() => handleThumbsUp(threadItem.request_id)}
                      onThumbsDown={() =>
                        handleThumbsDown(threadItem.request_id)
                      }
                      onSubmitChatbotFeedback={handleQueryChatbotFeedback}
                      isLoadingFeedback={feedbackLoading}
                      chart={threadItem?.chart}
                      image={threadItem?.image}
                      totalResponseTime={threadItem?.response_total_time}
                      showFeedback={!info_agent_webview}
                      showReaction={!info_agent_webview}
                      showCode={!info_agent_webview}
                    />
                  )
                )}

                {isLoading && <MessageLeft isLoading={isLoading} />}
                <div
                  style={{ float: "left", clear: "both" }}
                  ref={messageEndRef}
                ></div>
              </Paper>
              <ChatbotTextInput
                info_agent_webview={info_agent_webview}
                isThreadMessageExist={thread.length > 0}
                submitInput={onPromptSubmit}
              />
            </Box>
          </Grid>
          {!info_agent_webview && (
            <Grid item xs={2.5}>
              <Box
                sx={{
                  height: "calc(100vh - 90px)",
                  marginLeft: 2,
                  borderRadius: 2,
                  p: 1,
                  // width: "250px",
                  bgcolor: "primary.light",
                  overflow: "auto",
                }}
              >
                <ChatbotSidebar data={chatbotSessionsData} />
              </Box>
            </Grid>
          )}
        </Grid>
      </MainCard>
    </div>
  );
}
