import { CHATBOT_API_KEY } from "@/config/env.config";
import { IConversation, IConversationEvents } from "@/models/conversation";
import { ISession } from "@/models/session";
import { IWhatsappUser } from "@/models/whatsapp";
import baseChatbotApi from "@/store/baseChatbotAPI";
import { BaseQueryFn, EndpointBuilder } from "@reduxjs/toolkit/query";
import baseChatbotSalesAgentAPI from "../baseChatbotSalesAgentAPI";

const getQueryChatbotEndpoint = ({
  builder,
  info_agent_webview = false,
}: {
  builder: EndpointBuilder<BaseQueryFn, string, string>;
  info_agent_webview?: boolean;
}) => {
  return {
    queryChatbot: builder.mutation<
      {
        request_id: string;
        status: string;
        message: string;
        data: {
          response: string;
          sql: string;
          image: string;
          chart: { link: string; type: string };
          response_time: {
            response_time_details: {
              pineconeinfo_meta: number;
              pinecone_feedback: number;
              init_db_connection: number;
              init_gcp: number;
              invoke_analyze_chart: number;
              invoke_analyze_question: number;
              Concurrent_tasks: number;
              invoke_generate_sql_query: number;
              execute_SQL_Query: number;
              invoke_generate_final_answer: number;
            };
            total_time: number;
          };
        };
      },
      {
        store_id: string;
        sku?: string | null;
        prompt: string;
        request_id: string;
        session_id: string;
        info_agent_webview?: boolean;
        isIntro?: boolean;
        events?: IConversationEvents;
        init_conversation?: boolean;
        isPublicSalesAgent?: boolean;
      }
    >({
      query: ({
        store_id,
        sku,
        prompt,
        request_id,
        session_id,
        events,
        init_conversation,
        isIntro,
        isPublicSalesAgent
      }) => ({
        url: `/LangQuery`,
        method: "post",
        access_token: "",
        headers: {
          ...(info_agent_webview && { "X-API-Key": CHATBOT_API_KEY }),
        },
        data: {
          store_id,
          sku,
          prompt,
          request_id,
          session_id,
          events: {
            ...events,
            url: events?.url ?? (window.location !== window.parent.location ? document.referrer : document.location.href),
          },
          init_conversation,
          isIntro,
          isPublicSalesAgent,
        },
      }),
    }),
  };
};

export const chatbotApi = baseChatbotApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      ...getQueryChatbotEndpoint({ builder }),
      messageReaction: builder.mutation<
        { data: { success: string } },
        { store_id: string; like: boolean; request_id: string }
      >({
        query: ({ store_id, like, request_id }) => ({
          url: `/FeedBack`,
          method: "post",
          access_token: "",
          data: { store_id, like, request_id },
        }),
      }),
      feedbackComment: builder.mutation<
        { data: { response: string; sql: string } },
        { store_id: string; comment: string; request_id: string }
      >({
        query: ({ store_id, comment, request_id }) => ({
          url: `/FeedBackComment`,
          method: "post",
          access_token: "",
          data: { store_id, comment, request_id },
        }),
      }),

      chatSessions: builder.query<
        { UserID: string; data: ISession[] },
        { store: string }
      >({
        query: ({ store }) => ({
          url: "/Sessions",
          method: "GET",
          access_token: "",
          params: {
            store,
          },
        }),
      }),

      llmAnalyzeImage: builder.query<{ response: string }, { url: string }>({
        query: ({ url }) => ({
          url: "/llm/analyze_image",
          method: "GET",
          access_token: "",
          params: {
            url,
          },
        }),
      }),

      conversation: builder.query<
        { SessionID: string; UserID: string; data: IConversation[] },
        { session_id: string }
      >({
        query: ({ session_id }) => ({
          url: `/Conversation`,
          method: "GET",
          access_token: "",
          params: { session_id },
        }),
      }),

      whatsappLink: builder.query<{ whatsapp_link: string }, { store: string }>(
        {
          query: ({ store }) => ({
            url: "/whatsapp/link",
            method: "GET",
            access_token: "",
            params: {
              store,
            },
          }),
        }
      ),

      whatsappUsers: builder.query<
        IWhatsappUser[],
        { store_id?: string; start_date?: string; end_date?: string }
      >({
        query: ({ store_id, start_date, end_date }) => ({
          url: "/whatsapp/users",
          method: "GET",
          access_token: "",
          params: {
            store_id,
            start_date,
            end_date,
          },
        }),
      }),

      whatsappUserConversation: builder.query<
        IConversation[],
        {
          user_id: string;
          store_id?: string;
          start_date?: string;
          end_date?: string;
        }
      >({
        query: ({ user_id, store_id, start_date, end_date }) => ({
          url: "/whatsapp/users/conversation",
          method: "GET",
          access_token: "",
          params: {
            user_id,
            store_id,
            start_date,
            end_date,
          },
        }),
      }),
    };
  },
});

export const chatbotSalesAgentApi = baseChatbotSalesAgentAPI.injectEndpoints({
  endpoints: (builder) => {
    return {
      ...getQueryChatbotEndpoint({ builder, info_agent_webview: true }),
    };
  },
});

export const {
  useQueryChatbotMutation,
  useMessageReactionMutation,
  useFeedbackCommentMutation,
  useChatSessionsQuery,
  useWhatsappLinkQuery,
  useWhatsappUsersQuery,
  useWhatsappUserConversationQuery,
  useLazyWhatsappUserConversationQuery,
  useLazyLlmAnalyzeImageQuery,
} = chatbotApi;
