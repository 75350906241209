import { useState, useEffect } from "react";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

const TypingMarkdown = ({ message }: { message: string }) => {
  const [displayedText, setDisplayedText] = useState("");
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (!message) return;

    const interval = setInterval(() => {
      setDisplayedText((prev) => prev + message[index]);
      setIndex((prev) => prev + 1);
    }, 0.5);

    if (index === message.length) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [message, index]);
  return (
    <Markdown
      remarkPlugins={[remarkGfm]}
      components={{
        ul: ({ ...props }) => (
          <ul style={{ margin: "1em 0", paddingLeft: "1.5em" }} {...props} />
        ),
        ol: ({ ...props }) => (
          <ol style={{ margin: "1em 0", paddingLeft: "1.5em" }} {...props} />
        ),
        h1: ({ ...props }) => (
          <h1 style={{ marginTop: "1em", marginBottom: "0.5em" }} {...props} />
        ),
        h2: ({ ...props }) => (
          <h2 style={{ marginTop: "1em", marginBottom: "0.5em" }} {...props} />
        ),
        h3: ({ ...props }) => (
          <h3 style={{ marginTop: "1em", marginBottom: "0.5em" }} {...props} />
        ),
        h4: ({ ...props }) => (
          <h4 style={{ marginTop: "1em", marginBottom: "0.5em" }} {...props} />
        ),
        h5: ({ ...props }) => (
          <h5 style={{ marginTop: "1em", marginBottom: "0.5em" }} {...props} />
        ),
        h6: ({ ...props }) => (
          <h6 style={{ marginTop: "1em", marginBottom: "0.5em" }} {...props} />
        ),
        a: ({ ...props }) => {
          const isEmail = props.href && props.href.startsWith("mailto:");
          return isEmail ? (<a target="_blank" rel="noopener noreferrer" {...props} />) : (
            <span>
              <strong>{props.children}</strong>
              &nbsp;
              <a target="_blank" rel="noopener noreferrer" {...props}>
                <span>({props.href})</span>
              </a>
            </span>
          );
        },
      }}
    >
      {displayedText}
    </Markdown>
  );
};

export default TypingMarkdown;
